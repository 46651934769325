import React, { useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./TechstackMobile.module.scss"
import "./TechstackMobile.scss"

const data = [
  {
    title: "Front-end",
    tech: [
      {
        name: "HTML",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html_48a83c50a7.svg",
      },
      {
        name: "CSS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/css_36f2d720ad.svg",
      },
      {
        name: "Javascript",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_ca3ec6e863.svg",
      },
      {
        name: "SaaS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sass_1e855d98d5.svg",
      },
      {
        name: "React JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_b934c6ba36.svg",
      },
      {
        name: "Vue JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_871a7a0824.svg",
      },
      {
        name: "Angular",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/angular_17e6e618df.svg",
      },
      {
        name: "Meteor JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/meteor_2ef9591f7e.svg",
      },
      {
        name: "Nuxt JS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nuxt_8908ee250f.svg",
      },
      {
        name: "WebGL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/webgl_7650b49f3f.svg",
      },
    ],
  },
  {
    title: "Back-End",
    tech: [
      {
        name: "Node-js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/node_js_ba6f19e132.svg",
      },
      {
        name: "Python",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/python_9828a276c4.svg",
      },
      {
        name: "Elixir",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/elixir_617ae7f1a2.svg",
      },
      {
        name: "Ruby",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ruby_632c73bf20.svg",
      },
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_af6208f7f7.svg",
      },
      {
        name: "PhP",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/php_1d3b64b85d.svg",
      },
      {
        name: "Golang",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/golang_897a5b704b.svg",
      },
      {
        name: "C#",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/c_e3bc66767f.svg",
      },
      {
        name: "C++",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/C_4cd508eb6d.svg",
      },
      {
        name: "Rust",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rust_ae892f1b03.svg",
      },
      {
        name: "Nest-js",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/nest_js_347dd24c27.svg",
      },
      {
        name: "Dot-net-core",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dot_net_core_5afd52a4f6.svg",
      },
    ],
  },
  {
    title: "Low/No Code",
    tech: [
      {
        name: "Shopify",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/shopify_79bbbaae8a.svg",
      },
      {
        name: "Wordpress",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/wordpress_820a8832b3.svg",
      },
      {
        name: "Strapi",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/strapi_1790dd0526.svg",
      },
      {
        name: "bubble.io",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/bubble_io_cdf480b2d8.svg",
      },
      {
        name: "builder.io",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/builder_io_c982afbf9c.svg",
      },
      {
        name: "Zoho",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/zoho_37c3233344.svg",
      },
      {
        name: "Zapier",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/zapier_a5c6910dc6.svg",
      },
      {
        name: "Webflow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/webflow_116678b7cd.svg",
      },
      {
        name: "Wix",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/wix_81cd70ca6d.svg",
      },
    ],
  },
  {
    title: "Database",
    tech: [
      {
        name: "Firebase",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_c6c4691af1.svg",
      },
      {
        name: "Mongodb",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mangodb_0b552dbfc4.svg",
      },
      {
        name: "Postgresql",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgresql_018ca56509.svg",
      },
      {
        name: "Couch-db",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/couch_db_9d0e36ccfe.svg",
      },
      {
        name: "Db",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/db_469cb3200a.svg",
      },
      {
        name: "Sqlite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sqlite_9fc822116d.svg",
      },
      {
        name: "Ms-sql",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ms_sql_c9279e2570.svg",
      },
      {
        name: "Aws-dynamodb",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_dynamodb_8ce325843f.svg",
      },
      {
        name: "Oracle",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_6be07ed081.svg",
      },
      {
        name: "Mysql",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mysql_99960cd0a3.svg",
      },
      {
        name: "Redis",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/redis_8ef174bc41.svg",
      },
    ],
  },
  {
    title: "DevOps",
    tech: [
      {
        name: "Aws",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_858fab8414.svg",
      },
      {
        name: "Gcp",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gcp_9ae948ef34.svg",
      },
      {
        name: "Azure",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_77461d8935.svg",
      },
      {
        name: "Ibm-cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ibm_cloud_0c1b0efd78.svg",
      },
      {
        name: "Digital-ocean",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_ocean_10bf44a123.svg",
      },
      {
        name: "Oracle-cloud",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/oracle_cloud_8ae9580632.svg",
      },
      {
        name: "Puppet",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/puppet_0e8931f35e.svg",
      },
      {
        name: "Kubernetes",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kubernetes_2023fef2be.svg",
      },
      {
        name: "Docker",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/docker_d5a4690b78.svg",
      },
      {
        name: "Jenkins",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jenkins_54e53e2312.svg",
      },
      {
        name: "Chef",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/chef_062f8ac085.svg",
      },
      {
        name: "Terraform",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/terraform_icon_svgrepo_com_1_c6c3806238.svg",
      },
    ],
  },
  {
    title: "Mobile",
    tech: [
      {
        name: "Android",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_ce04718bfc.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_58ea9044fc.svg",
      },
      {
        name: "Swift",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/swift_db47cc1031.svg",
      },
      {
        name: "Objective-c",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/objective_c_e29dc3c111.svg",
      },
      {
        name: "React-native",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_02c3cbfeb9.svg",
      },
      {
        name: "Ionic",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ionic_635de2bab9.svg",
      },
      {
        name: "Flutter",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flutter_9152091361.svg",
      },
      {
        name: "IoS",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/ios_1cf73b4c2c.svg",
      },
      {
        name: "Pwa",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pwa_bd9f90b74c.svg",
      },
    ],
  },
  {
    title: "AI & ML",
    tech: [
      {
        name: "Tensorflow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/tensorflow_a440f904bd.svg",
      },
      {
        name: "Keras",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/keras_f4bc3ea67b.svg",
      },
      {
        name: "Pytorch",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pytorch_74c1ee61bf.svg",
      },
      {
        name: "Lisp",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_9e3e298a1e.svg",
      },
      {
        name: "NTKL",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/NTKL_502fd6f6af.svg",
      },
      {
        name: "Spacy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/spacy_b3ddd10154.svg",
      },
      {
        name: "Open-ai",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/open_ai_dd283880be.svg",
      },
      {
        name: "Ploty",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/image_29_21354b97d2.svg",
      },
      {
        name: "Matplotlib",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/matplotlib_76ece39e9b.svg",
      },
      {
        name: "Pandas",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/pandas_4f33ff9097.svg",
      },
      {
        name: "Opencv",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/opencv_4851d8bac3.svg",
      },
      {
        name: "Numpy",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/numpy_8d321b6037.svg",
      },
      {
        name: "Scikit_learn",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Scikit_learn_65468053de.svg",
      },
      {
        name: "Mistral",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mistral_color_1_38e69aaec1.svg",
      },
      {
        name: "DeepSeek",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/deepseek_color_1_1_ccd7f26995.svg",
      },
      {
        name: "llamas",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/icons8_meta_1_f2fbd2c5bc.svg",
      },
      {
        name: "Langflow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Simple_Icons_Langflow_1_ea9a5a3ebc.svg",
      },
    ],
  },
]

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle
      as={Card.Header}
      onClick={decoratedOnClick}
      className={isCurrentEventKey ? styles.currentCard : ""}
    >
      <div>{children}</div>
    </Accordion.Toggle>
  )
}


// const [searchTerm, setSearchTerm] = useState("")

// const handleTabClick = label => {
//   setActiveTab(label)
// }

// const handleSearchChange = event => {
//   setSearchTerm(event.target.value)
// }

// // const searchTech = term => {
// //   let filteredData = []
// //   data.forEach(category => {
// //     category.tech.forEach(tech => {
// //       if (tech.name.toLowerCase().includes(term.toLowerCase())) {
// //         filteredData.push(tech)
// //       }
// //     })
// //   })
// //   return filteredData
// // }
// // var filteredData = []
// // if (searchTerm) {
// //   filteredData = searchTech(searchTerm)
// // } else {
//   const filteredData = data.map(category => ({
//     ...category,
//     tech: category.tech.filter(tech =>
//       tech.name.toLowerCase().includes(searchTerm.toLowerCase())
//     ),
//   }))

const Technology2 = ({ strapiData }) => {
  return (
    <section className={styles.section}>
      <Container>
        <p className={styles.ser}>Tech Stack</p>
        <div className={styles.headSearch}>
          <h2 className={styles.heading}>Technologies & tools</h2>
          {/* <input
            type="text"
            placeholder="What are you looking for?"
            // value={searchTerm}
            // onChange={handleSearchChange}
            className={styles.searchBar}
          /> */}
        </div>
        <Row className="justify-content-center gap-30">
          {data &&
            data?.map((e, i) => (
              <Col xl={6} lg={6} md={6}>
                <div className="modelsProgress">
                  <Accordion>
                    <Card
                      key={i}
                      border="light"
                      style={{ overflow: "visible" }}
                    >
                      <ContextAwareToggle eventKey={`ek-${i}`}>
                        <div className={styles.faqsNum}>
                          <h3>{e?.title}</h3>
                        </div>
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={`ek-${i}`}>
                        <Card.Body className={styles.textColor}>
                          <div className={` ${styles.cardWrapper}`}>
                            {e?.tech &&
                              e?.tech.map((e, eIdx) => (
                                <div className={styles.arrowPoint}>
                                  <img
                                    src={e?.icon}
                                    decoding="async"
                                    loading="lazy"
                                    style={{ width: "48px", height: "48px" }}
                                  />
                                  {/* <p className={`${styles.skill}`}>{skill}</p> */}
                                </div>
                              ))}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  )
}

export default Technology2
